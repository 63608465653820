import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const GoogleTagManager: React.FC = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
      });
    }
  }, []);

  return null;
};

export default GoogleTagManager;

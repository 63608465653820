import React, { Fragment } from 'react';
import i18n from 'i18next';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';

import { Language } from 'src/client/enums/language.enum';

import { LanguageProps } from 'src/client/interfaces/language.interface';

const languages: LanguageProps[] = [
  {
    name: 'Magyar',
    code: Language.HU,
    src: require('src/client/assets/images/hungarian.webp'),
  },
  {
    name: 'English',
    code: Language.EN,
    src: require('src/client/assets/images/english.webp'),
  },
  {
    name: 'Deutsch',
    code: Language.DE,
    src: require('src/client/assets/images/deutsch.webp'),
  },
];

const LanguageSwitcher: React.FC = () => {
  const currentLanguage = languages.find(
    (language) => language.code === i18n.language,
  );

  return (
    <Menu as="div" className="relative inline-block text-left">
      <MenuButton className="inline-flex justify-center px-4 py-2 outline-none">
        <img
          src={currentLanguage?.src}
          alt={currentLanguage?.name}
          className="max-w-[30px]"
          loading="lazy"
        />
      </MenuButton>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="origin-top-right absolute left-1/2 -translate-x-1/2 w-auto bg-mine-shaft shadow-2xl">
          <div className="py-1">
            {languages
              .filter(
                (language: LanguageProps) => language.code !== i18n.language,
              )
              .map((language: LanguageProps, index: number) => {
                return (
                  <MenuItem key={index}>
                    {() => (
                      <div
                        className="block px-4 py-2 hover:bg-green-600 cursor-pointer"
                        onClick={() => i18n.changeLanguage(language.code)}
                      >
                        <img
                          src={language.src}
                          alt={language.name}
                          className="max-w-[30px]"
                          loading="lazy"
                        />
                      </div>
                    )}
                  </MenuItem>
                );
              })}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};

export default LanguageSwitcher;
